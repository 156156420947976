.hero{
    height: 87vh;
    /* background: linear-gradient(180deg,#fff7e1,#e1ffea22,60%); */
    background:#37475A;
    display: flex;
    
    /* background-image: url("../Assets/bg_4.jpg"); */
    background-position: center;
    background-size: cover;
    
}


.hero-left{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding-left: 150px;
    line-height: 1.1;
}

.hero-left h2{
    color: black;
    font-size: 26px;
    font-weight: 600;
}

.hero-left p{
    color:black;
    font-size: 90px;
    font-weight: 700;
    
}

.hero-left p span{
   
    color: black;
    
}

.hero-hand-icon{
    display: flex;
    align-items: center;
    gap: 20px;
}

.hero-hand-icon img{
    width: 105px;
}

.hero-latest-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:15px;
    width: 310px;
    height: 70px;
    border-radius: 75px;
    margin-top: 30px;
    background: #232f3e;
    color: white;
    font-size: 22px;
    font-weight: 5;
    border: 2px solid black;
    cursor: pointer;
}

.hero-right{
    flex: 1;
    display: flex;
    justify-content: center ;
    align-items: center;
    
}

.hero-right img{
    width: 450px;
}