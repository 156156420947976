.offer2{
    display: flex;
    width:75%;
    height: 70vh;
    margin: auto;
    padding: 0px 140px;
    margin-bottom: 90px;
    /* background-image: url(../Assets/offer2_bg.webp); */
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    background-color:#37475A ;
    
}

.offer2 h1{
    font-size: 60px;
} 

.offer2-right img{
    width: 600px;
} 


