.newsletter{
    width: 75%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 0 140px;
    margin-bottom: 70px;
    background-color:#37475A;
    gap: 30px;
    
}

.newsletter h1{
    color: black;
    font-size: 55px;
    font-weight: 600;
}

.newsletter p{
    color: black;
    font-size: 20px;
}
.newsletter div{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    width: 730px;
    height: 50px;
    border-radius: 80px;
    /* border: 2px solid black; */
}

.newsletter input{
    width: 500px;
    margin-left: 30px;
    border: none;
    outline: none;
    color:   #FEBD69;
    font-family: Poppins;
}

.newsletter div button{
    width: 210px;
    height: 50px;
    border-radius: 80px;
    background: black;
    color:  white;
    font-size: 16px;
    cursor: pointer;
    border: none;
}