.newcollections{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 155vh;
    gap: 10px;
    margin-top: 50px;
}

.newcollections h1{
    color:  #232F3E;
    font-size: 50px;
    font-weight: 600px;
}

.newcollections hr{
    width: 100px;
    height: 6px;
    
    background-color: #FEBD69;
}

.collections{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 60px;
    gap: 30px;
}