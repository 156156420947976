.offers{
    display: flex;
    width: 75%;
    height: 60vh;
    margin: auto;
    padding: 0px 140px;
    margin-bottom: 90px;
    background-color: #37475A;
}

.offers-left{
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.offers-left h1{
    color: black;
    font-size: 80px;
    font-weight: 600;
}

.offers-left p{
    color:  black;
    font-size: 22px;
    font-weight: 600;
}

.offers-left button{
    width: 282px;
    height: 70px;
    border-radius: 75px;
    cursor: pointer;
    font-size: 22px;
    font-weight: 500;
    margin-top: 30px;
    border: 2px solid black;
    color: white;
    background: #232F3E;
}

.offers-right{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 12px;
    padding-left: 20px;
}

.offers-right img{
      width:270px;
      
}