.item{
    
    width: 275px;
    /* background-color:#37475A; */
    border-radius: 30px;
    border: 2px solid  #37475A;
}

.item img{
    margin: 10px;
    border-radius: 30px;
    width: 255px;
    height: 275px;
}

.item p{
    padding-left: 30px;
  font-size: 16px;
    width: 200px;
    margin: 6px 0;
}

.item-prices{
    padding-left: 10px;
    display: flex;
    justify-content: center;
    gap: 20px;
    padding-bottom: 2px;
}

.item-price-new{
    color: #232F3E;
    font-size: 20px;
    font-weight: 600;
}

.item-price-old{
    color: #232F3E ;
    font-size: 20px;
    font-weight: 500;
    text-decoration: line-through;
}

.item:hover{
    transform: scale(1.02);
    transition: 0.3s;
}

.item-btn{
   color:  #FEBD69;
   margin-left: 90px;
   margin-right: 90px;
   margin-top: 2px;
   margin-bottom: 6px;
   width: 95px;
   height: 35px;
   border: none;
   border-radius: 50px;
   background-color:#232F3E;
   font-weight: 600;
}

.item-btn:hover{
    color: white;
    cursor: pointer;
    transform: scale(1.02);
    
}