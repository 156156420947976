.navbar{
    display: flex;
    justify-content: space-around;
    padding:16px 0 16px 0;
    box-shadow: 0 1px 3px -2px black;
    width: 100%;
    height: 40px;
    background-color:  #232F3E ;
}

.nav-logo{
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav-logo p{
    color: #FEBD69;
    font-size: 38px;
    font-weight: 600;
}

.nav-logo p span{
    color:#FEBD69 ;
}



.nav-menu{
    display: flex;
    list-style: none;
    gap: 20px;
    font-size: 20px;
    align-items: center;
    color:black;
    font-weight: 500;
    

}


.search-bar{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 50px;
    padding: 10px 20px;
    
}

.search-bar input{
    border: 0;
    outline: 0;
    font-size: 20px;
    padding: 6px;
    background: transparent;
    max-width: 190px;
}

.search-bar input::placeholder{
    color: black;
}

.search-bar img{
    width: 25px;
    padding: 10px;
}

.clicks{
    display: flex;
    gap: 20px;

}

.nav-menu li{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3px;
    cursor: pointer;
    
    
}

.nav-menu hr{
    border: none;
    width: 80%;
    height: 3px;
    border-radius: 10px;
    background-color:#FEBD69;
}

.nav-login-cart{
    display: flex;
    align-items: center;
    gap: 25px;
}

.nav-login-cart button{
    width: 127px;
    height: 42px;
    outline: none;
    border: 2px solid white;
    border-radius: 75px;
    color: white;
    font-size: 20px;
    font-weight: 500;
    background:  #232F3E;
    cursor: pointer;
}

.nav-login-cart button:active{
    background: #f3f3f3;
    color:  #232F3E;
}
.nav-cart-count{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    margin-top: -35px;
    margin-left: -40px;
    border-radius: 11px;
    font-size: 14px;
    background-color: red;
    color: white;
}