.footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    background-color: #37475A;
}

.footer-logo{
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer-logo p{
    color:#FEBD69;
    font-size: 46px;
    font-weight: 700;
    margin-top: 20px;
}

.footer-logo p span{
    color: #FEBD69;
}

.footer-link ul{
    display: flex;
    list-style: none;
    gap: 50px;
    color: white;
    font-size: 20px;
}

.footer-link li{
    cursor: pointer;
}

.footer-social-icon{
    display: flex;
    gap: 20px;
}

.footer-icon-container{
    padding: 10px;
    padding-bottom: 6px;
    background:transparent;
    border: 1px solid white;
}

.footer-copyright{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
    margin-bottom: 20px;
    color: white;
    font-size: 20px;
}

.footer-copyright hr{
    width: 80%;
    border-radius: 10px;
    border: none;
    height: 2px;
    background: black;
}