.brands{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 115vh;
    gap: 10px;
    margin-top: 30px;
    
}

.brands h1{
    color: #232F3E ;
    font-size: 50px;
    font-weight: 600px;
}

.brands hr{
    width: 100px;
    height: 6px;
    
    background-color: #FEBD69;
}

.brand_cards{
    
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-top: 60px;
        gap: 30px;

}
.brand-card{
    
    width: 280px;
    background-color:  #37475A;
    border-radius: 30px;
}

.brand-card img{
    width: 270px;
    margin-top:5px ;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 30px;
}
.brand-card h3{
    font-size: 20px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    
}

.brand-card:hover{
    transform: scale(1.05);
    transition: 0.3s;
    cursor: pointer;
}