.popular{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    gap: 10px;
    margin-top: 50px;
}

.popular h1{
    color: #232F3E;
    font-size: 50px;
    font-weight: 600px;
}

.popular hr{
    width: 100px;
    height: 6px;
    background-color: #FEBD69;
}

.popluar-item{
    display: flex;
    margin-top: 50px;
    gap: 30px;
    
}